import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import { Link, useIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

export const query = graphql`
  {
    fa: allContentfulGallery(filter: { node_locale: { eq: "fa" } }) {
      nodes {
        contentful_id
        name
        featuringImage {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          title
          description
        }
      }
    }
    en: allContentfulGallery(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        contentful_id
        name
        featuringImage {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          title
          description
        }
      }
    }
  }
`

const Gallery = props => {
  const intl = useIntl()
  return (
    <>
      <SEO title={intl.formatMessage({ id: "gallery" })} description={intl.formatMessage({ id: "galleryDescription" })} />
      <div className="container">
        <div className="row">
          {props.data.en.nodes.map((g, i) => {
            const fa = props.data.fa.nodes[i]
            return (
              <Link
                key={g.contentful_id}
                to={`/gallery/${g.name}`}
                className="col-md-4 col-sm-12 col-lg-3 gallery-image-link"
              >
                <div className="gallery-image-link-content">
                  <Img
                    fluid={g.featuringImage.fluid}
                    alt={intl.locale === "en" ? g.featuringImage.description : fa.featuringImage.description}
                  />
                  <div className="dark-gradient" />
                  <div className="gallery-image-link-label">{intl.locale === "en" ? g.name : fa.name}</div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Gallery
